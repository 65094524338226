import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import Loading from '@/components/loading';
import StyledImage from '@/components/styledImage';
import { axiosClient } from '@/data';
import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommerceWrite } from '@/data/commerce/commerce.graphql';
import { InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { OrderWrite } from '@/data/commerce/order.graphql';
import idPick from '@/helpers/idPick';
import safeDateFns, { safeFormatInTimeZone } from '@/helpers/safeFormat';
import SyncToClover from '@/helpers/syncToClover';
import useAccountType from '@/helpers/useAccountType';
import { InvoiceSyncLineItemsRead } from '@/pages/dashboard/commerce/invoices/invoiceGQL';
import { updateStock } from '@/pages/dashboard/commerce/orders/utils';
import CloverPaymentDetails from '@/pages/dashboard/commerce/payment/clover/details';
import { useFetchCloverCards } from '@/pages/settings/cards';
import { useEvents } from '@/providers/event';
import { useModalControls } from '@/providers/modal';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import {
	Gateway,
	MutationCommerceWriteArgs,
	MutationInvoiceWriteArgs,
	MutationOrderWriteArgs,
	Order,
	Payment,
	QueryLineItemsReadArgs,
} from '@/types/schema';
import { ArrowBackIos as ArrowBackIosIcon, Payment as PaymentIcon } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Chip,
	Collapse,
	Divider,
	FormControlLabel,
	Paper,
	Radio,
	RadioGroup,
	Stack,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import axios from 'axios';
import { isEmpty, isString, round, startCase, toLower } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { Fragment, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup } from 'react-transition-group';
import { findEarliestDate } from './repeatDateModal/utils';

export function RecurringSettings( { order }: { order: Order } ) {
	const { t } = useTranslation();
	
	if ( !order?.standingData ) return null;
	
	const startingDate: Date = findEarliestDate( order?.standingData );
	
	return (
		<Stack mb={1}>
			{order.standingData.type !== 'NONE' && (
				<Stack>
					<Stack direction='row' alignItems='center' justifyContent='space-between'>
						<Typography>
							{t( 'common:repeats-every' )}
						</Typography>
						<Chip
							label={startCase( toLower( order.standingData.type ) )}
							color='amethyst'
							variant='alpha'
						/>
					</Stack>
					<Divider sx={{ my: 1 }}/>
				</Stack>
			)}
			{order.standingData.type === 'WEEK' && (
				<Stack>
					<Stack direction='row' alignItems='center' justifyContent='space-between'>
						<Typography>
							On
						</Typography>
						<Stack
							spacing={1}
							direction='row'
							sx={{ '& .MuiAvatar-root': { width: 30, height: 30, fontSize: 12 } }}>
							{[ 'Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa' ].map( ( day ) => (
								<Avatar
									key={day}
									sx={order.standingData.repeat[ day ]
										? { bgcolor: 'primary.main' }
										: undefined}>
									{day}
								</Avatar>
							) )}
						</Stack>
					</Stack>
					<Divider sx={{ my: 1 }}/>
				</Stack>
			)}
			{Boolean( order.standingData.include?.length ) && (
				<Stack>
					<Stack direction='row' alignItems='start' justifyContent='space-between'>
						<Typography>
							Includes
						</Typography>
						<Stack
							direction='row'
							alignItems='end'
							justifyContent='end'
							spacing={1}
							sx={{ flexWrap: 'wrap' }}>
							{order.standingData.include?.map( ( date, index ) => (
								<Box key={index} sx={{ pt: index > 1 ? .5 : 0 }}>
									<Chip
										key={index}
										variant='alpha'
										color='warning'
										label={safeFormatInTimeZone( date, 'PPP' )}
									/>
								</Box>
							) )}
						</Stack>
					</Stack>
					<Divider sx={{ my: 1 }}/>
				</Stack>
			)}
			{Boolean( order.standingData.exclude?.length ) && (
				<Stack>
					<Stack direction='row' alignItems='start' justifyContent='space-between'>
						<Typography>
							Excludes
						</Typography>
						<Stack
							direction='row'
							alignItems='end'
							justifyContent='end'
							spacing={1}
							sx={{ flexWrap: 'wrap' }}>
							{order.standingData.exclude?.map( ( date, index ) => (
								<Box key={index} sx={{ pt: index > 1 ? .5 : 0 }}>
									<Chip
										key={index}
										variant='alpha'
										color='error'
										label={safeFormatInTimeZone( date, 'PPP' )}
									/>
								</Box>
							) )}
						</Stack>
					</Stack>
					<Divider sx={{ my: 1 }}/>
				</Stack>
			)}
			<Stack direction='row' alignItems='center' justifyContent='space-between'>
				<Typography>
					{t( 'common:starts' )}
				</Typography>
				<Typography>
					{safeFormatInTimeZone( order.standingData.type === 'NONE'
						? safeDateFns.min( order?.standingData.include )
						: startingDate, 'PPP' )}
				</Typography>
			</Stack>
			<Divider sx={{ my: 1 }}/>
			<Stack direction='row' alignItems='center' justifyContent='space-between'>
				<Typography>
					{t( 'common:ends' )}
				</Typography>
				{order.standingData.ends === 'OCCURRENCES' ? (
					<Stack direction='row' alignItems='center' spacing={1}>
						<Typography color='text.secondary' component='span'>
							After
						</Typography>
						<Chip
							label={order.standingData.occurrences}
							color='amethyst'
							variant='alpha'
						/>
						<Typography color='text.secondary' component='span'>
							{order.standingData.occurrences > 1 ? 'occurrences' : 'occurrence'}.
						</Typography>
					</Stack>
				) : order.standingData.ends === 'DATE' ? (
					<Typography>
						{safeFormatInTimeZone( order.standingData.endDate, 'PPP' )}
					</Typography>
				) : (
					<Typography color='text.secondary'>
						No end date set
					</Typography>
				)}
			</Stack>
		</Stack>
	);
}

export default function RecurringModal( { formOrder }: { formOrder: Order } ) {
	const { closeModal } = useModalControls();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	
	const isCloverAccount = useAccountType( 'CLOVER' );
	const event = useEvents();
	const onSubmit = useRef<() => Promise<Payment>>();
	
	const [ order, setOrder ] = useState<Order>( formOrder || null );
	const [ step, setStep ] = useState( 0 );
	const [ recurringType, setRecurringType ] = useState( '' );
	const [ cardId, setCardId ] = useState( '' );
	const [ cardFirstSix, setCardFirstSix ] = useState( '' );
	const [ cardLastFour, setCardLastFour ] = useState( '' );
	const [ newCardOption, setNewCardOption ] = useState( '' );
	
	const client = order.client;
	const [ loading, cards, fetchCloverCards ] = useFetchCloverCards( client?.id, client?.externalId );
	
	if ( !order ) {
		return <Typography>{t( 'common:no-invoice-found' )}</Typography>;
	}
	
	return (
		<ResponsiveModalContainer
			title={t( 'common:setup-recurring' )}
			secondaryTitle={`${startCase( toLower( order?.type ) )} #${order?.number} ${client
				? `- ${client?.email || client?.name}`
				: ''}`}>
			<Stepper
				orientation='vertical'
				activeStep={step}
				sx={{ '.MuiPaper-root': { bgcolor: 'background.default' } }}>
				<Step key={0}>
					<StepLabel>
						<Typography variant='h5'>{t( 'common:when' )}</Typography>
					</StepLabel>
					<StepContent>
						<Fragment>
							<RecurringSettings order={order}/>
							<Button
								variant='contained'
								color='primary'
								onClick={() => setStep( 1 )}>
								{t( 'common:continue' )}
							</Button>
						</Fragment>
					</StepContent>
				</Step>
				<Step key={1}>
					<StepLabel>
						<Typography variant='h5'>{t( 'common:type' )}</Typography>
					</StepLabel>
					<StepContent TransitionProps={{ unmountOnExit: false }}>
						<RadioGroup
							value={recurringType}
							onChange={( e ) => setRecurringType( e.target.value )}>
							<FormControlLabel
								value='invoice'
								control={<Radio/>}
								label={`${t( 'common:create' )} ${startCase( toLower( order.type ) )} ${isCloverAccount
									? t( 'common:sync-with-clover' )
									: ''}`}
							/>
							<FormControlLabel
								value='send'
								control={<Radio/>}
								label={`${t( 'common:create' )} ${startCase( toLower( order.type ) )}, ${isCloverAccount
									? t( 'common:and-sync' )
									: ''} ${t( 'common:send-to-my-client' )}`}
							/>
							{isCloverAccount && (
								<FormControlLabel
									disabled={order.gateway?.external !== 'CLOVER' || !order.externalId}
									value='charge'
									control={<Radio/>}
									label={(
										<Stack direction='row' alignItems='center' spacing={1}>
											<Typography>
												{`${t( 'common:create' )} ${startCase( toLower( order.type ) )}, ${t( 'common:charge-send-receipt' )}`}
											</Typography>
											{( order.gateway?.external !== 'CLOVER' || !order.externalId ) && (
												<Tooltip title={t( 'common:sync-to-add-card' )}>
													<Box>
														<AsyncLoadingButton
															variant='outlined'
															sx={{ width: 100 }}
															startIcon={(
																<StyledImage
																	alt='clover-pos-image'
																	src='/images/clover-icon.png'
																	width='20px'
																	height='20px'
																/>
															)}
															onClick={async () => {
																try {
																	enqueueSnackbar( `Please wait while the ${toLower( order.type )} is being synced...`, { variant: 'info' } );
																	
																	let lineItems = order?.lineItems;
																	if ( isEmpty( lineItems ) ) {
																		const { lineItemsRead } = await queryGraphQL<QueryLineItemsReadArgs>( {
																			query    : InvoiceSyncLineItemsRead,
																			variables: {
																				options: {
																					limit : 1000,
																					filter: { order: order.id },
																				},
																			},
																		} );
																		lineItems = lineItemsRead?.items;
																	}
																	
																	const { commerceWrite } = await mutateGraphQL<MutationCommerceWriteArgs>( {
																		mutation : CommerceWrite,
																		variables: {
																			id    : order.id,
																			method: `Synced Recurring ${toLower( order.type )}`,
																			input : {
																				metadata : { hideCloverOrder: true },
																				lineItems: !isEmpty( lineItems )
																					? lineItems?.map( ( lineItem ) => ( {
																						...idPick( lineItem, [
																							'name',
																							'price',
																							'quantity',
																						] ),
																						isRevenue: false,
																					} ) )
																					: undefined,
																			},
																		},
																	} );
																	const cloverInvoice = await SyncToClover( commerceWrite );
																	setOrder( cloverInvoice?.commerce );
																	await updateStock( commerceWrite, commerceWrite.company, 'manualSyncStock', enqueueSnackbar );
																	event.emit( 'reload.singleQuery', true );
																} catch ( e ) {
																	const cloverErrors = e?.response?.data?.cloverErrors;
																	if ( cloverErrors ) {
																		enqueueSnackbar( isString( cloverErrors )
																			? cloverErrors
																			: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com', { variant: 'error' } );
																		return;
																	} else {
																		enqueueSnackbar( t( 'common:sync-clover-error' ), { variant: 'default' } );
																	}
																	
																	console.error( e );
																	throw e;
																}
															}}>
															Sync
														</AsyncLoadingButton>
													</Box>
												</Tooltip>
											)}
										</Stack>
									)}
								/>
							)}
						</RadioGroup>
						<Collapse in={Boolean( order.gateway?.external === 'CLOVER' || order.externalId ) && recurringType === 'charge'}>
							<RadioGroup
								value={newCardOption || cardId}
								onChange={( e ) => {
									if ( e.target.value === 'new card' ) {
										setNewCardOption( e.target.value );
										setCardId( '' );
									} else {
										setCardId( e.target.value );
										setNewCardOption( '' );
									}
								}}>
								{loading ? <Loading/> : (
									<TransitionGroup>
										{!isEmpty( cards ) ? cards?.filter( ( card ) => card?.token )
											.map( ( card, index ) => (
												<Collapse key={index} timeout={500}>
													<FormControlLabel
														key={card.id}
														sx={{ pl: 4 }}
														value={card.token}
														control={<Radio/>}
														label={(
															<Fragment>
																Saved Card {card.last4 ? `(**** ${card.last4})` : ''}
																{order.company.metadata.cardFee && !order.metadata?.enableCardFee && order.paidTotal === 0
																	? ` (${round( order.company.metadata.cardFee, 2 )}% Charge)`
																	: ''}
																<AsyncLoadingButton
																	color='error'
																	onClick={async () => {
																		const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/deleteCard`, {
																			id      : client.id,
																			cardId  : card.id,
																			sourceId: card.token,
																		} );
																		enqueueSnackbar( isString( data )
																			? data
																			: t( 'common:card-remove-success' ), { variant: 'success' } );
																		await fetchCloverCards();
																	}}>
																	{t( 'common:remove' )}
																</AsyncLoadingButton>
															</Fragment>
														)}
													/>
												</Collapse>
											) ) : null}
									</TransitionGroup>
								)}
								<FormControlLabel
									sx={{ pl: 4 }}
									value='new card'
									control={<Radio/>}
									label={t( 'common:add-new-card' )}
								/>
							</RadioGroup>
						</Collapse>
						<Collapse in={Boolean( newCardOption )} sx={{ pl: 4 }}>
							{recurringType === 'charge' && ( client?.externalId && client?.email )
								? (
									<Stack component={Paper} p={1}>
										<Typography mb={1} variant='h5'>
											{t( 'common:type-new-card' )}
										</Typography>
										<CloverPaymentDetails
											gateway={order.gateway as Gateway}
											method='card'
											createPayment={async ( args ) => {
												if ( !args.cardId ) {
													enqueueSnackbar( t( 'common:card-save-warning' ), { variant: 'error' } );
													return;
												}
												if ( args.cardId && cardFirstSix && cardLastFour ) {
													try {
														await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/saveCard`, {
															id          : client.id,
															cardId      : args.cardId,
															cardFirstSix: cardFirstSix,
															cardLastFour: cardLastFour,
															email       : client.email,
															meteredType : 'Save Card',
														} );
														setCardId( args.cardId );
														setNewCardOption( '' );
														await fetchCloverCards();
														
														// await fetchCards( client.id, setCards );
														enqueueSnackbar( t( 'common:card-save-success' ), { variant: 'success' } );
													} catch {
														enqueueSnackbar( t( 'common:card-save-error' ), { variant: 'error' } );
														return;
													}
												}
											}}
											onSubmit={onSubmit}
										/>
										<Stack direction='row' alignItems='center' spacing={1} my={1}>
											<TextField
												fullWidth
												placeholder={t( 'common:first-six-digit' )}
												inputProps={{ maxLength: 6, minLength: 6 }}
												value={cardFirstSix}
												onChange={( e ) => setCardFirstSix( e.target.value )}
											/>
											<Typography sx={{ whiteSpace: 'nowrap', textAlign: 'justify' }}>
												* * * * * *
											</Typography>
											<TextField
												fullWidth
												placeholder={t( 'common:last-four-digit' )}
												inputProps={{ maxLength: 4, minLength: 4 }}
												value={cardLastFour}
												onChange={( e ) => setCardLastFour( e.target.value )}
											/>
										</Stack>
										<AsyncLoadingButton
											variant='contained'
											color='primary'
											disabled={recurringType !== 'charge'}
											startIcon={<PaymentIcon/>}
											sx={{ alignSelf: 'end' }}
											onClick={async () => {
												if ( onSubmit.current ) {
													if ( recurringType === 'charge' ) {
														let message;
														if ( cardFirstSix?.length !== 6 ) {
															message = t( 'common:card-first-six' );
														} else if ( cardLastFour?.length !== 4 ) {
															message = t( 'common:card-last-four' );
														}
														
														if ( message ) {
															enqueueSnackbar( message, { variant: 'info' } );
															return;
														}
													}
												}
												await onSubmit.current();
												
											}}>
											Save Card
										</AsyncLoadingButton>
									</Stack>
								) : (
									<Typography>
										{!client ? t( 'common:add-client' ) : !client.externalId
											? t( 'common:not-clover-account' )
											: !client?.email ? 'Client email is required.' : ''}
									</Typography>
								)}
						</Collapse>
						<Stack spacing={1} direction='row' alignItems='center' mt={2}>
							<Button
								variant='outlined'
								startIcon={<ArrowBackIosIcon/>}
								onClick={() => setStep( 0 )}>
								{t( 'common:back' )}
							</Button>
							<AsyncLoadingButton
								disabled={!recurringType || recurringType === 'charge' && !cardId}
								variant='contained'
								color='primary'
								onClick={async () => {
									if ( order.type === 'ORDER' ) {
										await mutateGraphQL<MutationOrderWriteArgs>( {
											mutation : OrderWrite,
											variables: {
												id    : order.id,
												method: 'Activated Recurring With cardId',
												input : {
													standingActive: !order.standingActive,
													metadata      : { ...order.metadata, recurringType, recurringCardId: cardId },
												},
											},
										} );
									} else {
										await mutateGraphQL<MutationInvoiceWriteArgs>( {
											mutation : InvoiceWrite,
											variables: {
												id    : order.id,
												method: 'Activated Recurring With CardId',
												input : {
													standingActive: !order.standingActive,
													metadata      : { ...order.metadata, recurringType, recurringCardId: cardId },
												},
											},
										} );
									}
									closeModal();
								}}>
								Activate
							</AsyncLoadingButton>
							{/*{order?.client?.email && (*/}
							{/*	<AsyncLoadingButton*/}
							{/*		disabled={!recurringType || recurringType === 'charge' && !cardId}*/}
							{/*		variant='contained'*/}
							{/*		color='success'*/}
							{/*		onClick={async () => {*/}
							{/*			if ( order.type === 'ORDER' ) {*/}
							{/*				await mutateGraphQL<MutationOrderWriteArgs>( {*/}
							{/*					mutation : OrderWrite,*/}
							{/*					variables: {*/}
							{/*						id   : order.id,*/}
							{/*						input: {*/}
							{/*							standingActive: !order.standingActive,*/}
							{/*							sent          : true,*/}
							{/*							metadata      : { ...order.metadata, recurringType, recurringCardId: cardId },*/}
							{/*						},*/}
							{/*					},*/}
							{/*				} );*/}
							{/*			} else {*/}
							{/*				await mutateGraphQL<MutationInvoiceWriteArgs>( {*/}
							{/*					mutation : InvoiceWrite,*/}
							{/*					variables: {*/}
							{/*						id   : order.id,*/}
							{/*						input: {*/}
							{/*							standingActive: !order.standingActive,*/}
							{/*							sent          : true,*/}
							{/*							metadata      : { ...order.metadata, recurringType, recurringCardId: cardId },*/}
							{/*						},*/}
							{/*					},*/}
							{/*				} );*/}
							{/*			}*/}
							{/*			closeModal();*/}
							{/*		}}>*/}
							{/*		Activate & Email*/}
							{/*	</AsyncLoadingButton>*/}
							{/*)}*/}
						</Stack>
					</StepContent>
				</Step>
			</Stepper>
		
		</ResponsiveModalContainer>
	);
}
