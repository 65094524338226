import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineOppositeContent,
	TimelineSeparator,
} from '@mui/lab';
import { Box, Fade, Skeleton } from '@mui/material';
import React from 'react';

export default function RecurringInvoicesSkeleton( { length = 10, hasHeader }: {
	length?: number,
	hasHeader?: boolean
} ): JSX.Element {
	return (
		<Fade in timeout={1500}>
			<Box sx={hasHeader && { width: { xs: '100%', sm: '700px' }, p: 1 }}>
				{hasHeader && (
					<Box sx={{ px: 2 }}>
						<Skeleton
							animation='wave'
							width='100%'
							height='135px'
							sx={{ transform: 'unset', mt: 1 }}
						/>
						<Skeleton
							animation='wave'
							width='100%'
							height='100px'
							sx={{ transform: 'unset', my: 1 }}
						/>
					</Box>
				)}
				<Timeline sx={{ mt: !hasHeader && 0 }}>
					{[ ...new Array( length ) ].map( ( _, index ) => (
						<TimelineItem
							key={index}>
							<TimelineOppositeContent sx={{ flex: 0.1, maxWidth: 100, pl: 0 }}>
								<Skeleton
									key={index}
									animation='wave'
									width='87px'
									height='10px'
									sx={{ transform: 'unset', mt: 1 }}
								/>
							</TimelineOppositeContent>
							<TimelineSeparator>
								<TimelineDot/>
								<TimelineConnector/>
							</TimelineSeparator>
							<TimelineContent>
								<Skeleton
									key={index}
									animation='wave'
									width='300px'
									height='100px'
									sx={{ transform: 'unset' }}
								/>
							</TimelineContent>
						</TimelineItem>
					) )}
				</Timeline>
			</Box>
		</Fade>
	);
}

